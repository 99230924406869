import WorkspaceData from '../WorkspaceData';

export default class CodevertiseWorkspace extends WorkspaceData {
  constructor() {
    super();
    this.customerId = 'codevertise';
    console.log('Custom workspace loaded: codevertise');
  }

  getRenderAllowance() {
    return (typeof this.workspace.dynamic_signals !== 'undefined') && (this.workspace.dynamic_signals != null);
  }

  getRenderQuery() {
    if(this.getRenderAllowance()) {
      return `seed=${this.workspace.seed}&customerCode=${this.customerId}`;
    }
    this.dispatchEvent( {
      type: 'toast-msg',
      message: {
        type: 'warning',
        text:`missing dynamic signals`,
      },
    });

    return false;
  }

  setDynamicSignals(dynamicSignals) {
    if (dynamicSignals) {
      this.workspace.dynamic_signals = dynamicSignals;
      this.renderTemplate();
    }
  }
}